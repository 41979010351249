import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import StateContext from '../../context/StateContext';
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from 'react-html-parser';
import Carousel, { Modal, ModalGateway } from 'react-images';
import GatsbyLink from 'gatsby-link';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import SocialShare from '../../components/Commons/SocialShare';
import ProductMeta from '../../components/Product/ProductMeta';
import SliderArrow from '../../components/Commons/SliderArrow';
import StickyBox from 'react-sticky-box';
import Slider from 'react-slick';
import { getBuild } from '../../helpers/DrupalHelper';
import {
  jsonToReconfig,
  remoteImgExists,
  generateHash,
} from '../../helpers/ContentHelpers';
import { cleanDrupalMarkup, excludeNodeEnv } from '../../helpers/DomHelper';
import ThreeJs from '../../components/ThreeJs/index.js';

function BuildPage(props) {

  const data = useStaticQuery(graphql`
    {
      allFormlightEntities {
        nodes {
          v
          t
          p
        }
      }
    }
  `);


  const { product, parentProduct, alias } = props;

  const { addToCart, updateLoading } = useContext(StateContext);

  const [productCartCount, setProductCartCount] = useState(1);

  const gotoProduct = (pageLink) => {
    navigate(pageLink);
  };

  const increaseProductAmount = () => {
    let newQuantity = productCartCount + 1;
    setProductCartCount(newQuantity);
  };

  const decreaseProductAmount = () => {
    if (productCartCount === 1) return;
    let newQuantity = productCartCount - 1;
    setProductCartCount(newQuantity);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (event, photo, index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  if (product !== null) {
    const {
      title,
      part_number,
      images,
      imageGroup,
      json,
      three,
      v,
      product: parentNode,
      body,
    } = product;

    let jsonParsed = JSON.parse(json),
      reconfigBase;

    if (excludeNodeEnv() === true) {
      reconfigBase = jsonToReconfig(json);
      reconfigBase = `/create?b=${reconfigBase}`;
    }



    const {
      nodeName,
      nodeId,
      imageGroup: parentImageGroup,
      nodeImages,
    } = parentProduct;

    let newimages = [];
     newimages.push(...nodeImages);
     newimages.push(...images);

    let imageObjects = [];

    if (newimages.length > 0) {
      newimages.map((image, i) => {
        let imageSrc = newimages[i];

        let imageObject = {
          src: imageSrc,
          height: 1,
          width: 1,
        };
        imageObjects.push(imageObject);
      });
    }






    // let { images, imageGroup } = product;
    //
    // let jsonParsed = JSON.parse(json),
    //   reconfigBase;
    //
    // if (excludeNodeEnv() === true) {
    //   reconfigBase = jsonToReconfig(json);
    //   reconfigBase = `/create?b=${reconfigBase}`;
    // }
    //
    // let parentProduct = {};

    // nodes
    //   .filter((item) => item.nodeId === parentNode)
    //   .map((item) => (parentProduct = item));

    // const {
    //   nodeName,
    //   nodeId,
    //   imageGroup: parentImageGroup,
    //   nodeImages,
    // } = parentProduct;
    //
    // let newimages = [];
    //
    // images = images.filter((f) => f !== null);
    //
    // // newimages.push(...images);
    // newimages.push(...nodeImages);
    //
    // let imageObjects = [];
    //
    // if (newimages.length > 0) {
    //   newimages.map((image, i) => {
    //     let imageSrc = newimages[i];
    //
    //     let imageObject = {
    //       src: imageSrc,
    //       height: 1,
    //       width: 1,
    //     };
    //     imageObjects.push(imageObject);
    //   });
    // }

    let slideCount = 2,
      imageClass = `build-slider-slide-sm`,
      sliderMaxW = `700px`;

    if (newimages.length > 2) {
      slideCount = 3;
      imageClass = `build-slider-slide-md`;
      sliderMaxW = `900px`;
    }
    if (newimages.length > 3) {
      slideCount = 4;
      imageClass = `build-slider-slide-lg`;
      sliderMaxW = `none`;
    }

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: slideCount,
      slidesToScroll: 1,
      nextArrow: (
        <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-right" />
      ),
      prevArrow: (
        <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-left" />
      ),
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 690,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 460,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const imageFail = (e) => {
      // const src = e.target.src
    };

    return (
      <Layout>
        <div class="inspocontent-full">
          <StickyBox>
            <div class="threeBackdrop">
              {excludeNodeEnv() === true && <ThreeJs model3d={three} />}
            </div>
          </StickyBox>
          <div class="detailViewer pt-100">
            <div class="row align-items-center ccnInspoNav">
              <div class="col-xl-8 col-12">
                <div class="breadcrumb-area">
                  <ul class="page-breadcrumb-three">
                    <li>
                      <GatsbyLink to="/">Home</GatsbyLink>
                    </li>
                    <li>
                      <GatsbyLink to="/inspiration">Inspiration</GatsbyLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="product-details-content quickview-content">
              <h3 class="title">{nodeName}</h3>
              <h2 className="partNumber">{part_number}</h2>
              {newimages.length > 3 && (
                <Box>
                  <Box
                    className="build-slider"
                    style={{ position: `relative` }}
                  >
                    <p className="build-slider-p">
                      {newimages.length} images of customized {nodeName}
                    </p>
                    <Box style={{ maxWidth: sliderMaxW }}>
                      <Slider {...settings}>
                        {newimages.map((item, k) => {
                          let imageSrc = newimages[k];

                          return (
                            <div
                              className={`build-slider-slide ${imageClass}`}
                              onClick={(v) => {
                                openLightbox(v, imageSrc, k);
                              }}
                            >
                              <div className="build-slider-slide-inner"></div>
                              <img
                                src={item}
                                onError={(e) => imageFail(e)}
                                alt={nodeName}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </Box>
                    <ModalGateway>
                      {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                          <Carousel
                            currentIndex={currentImage}
                            views={imageObjects.map((x) => ({
                              ...x,
                              srcset: x.srcSet,
                              caption: x.title,
                            }))}
                          />
                        </Modal>
                      ) : null}
                    </ModalGateway>
                  </Box>
                </Box>
              )}
              <p class="product-price product-details-price">
                {' '}
                <span class="discounted-price">{v}</span>
              </p>
              <div class="pro-details-quality pro-details-btns-attchd">
                <div class="quantity quantity--2">
                  <input
                    type="text"
                    class="quantity-input"
                    name="qty"
                    id="qty-4"
                    value={productCartCount}
                  />
                  <div class="dec qtybutton" onClick={decreaseProductAmount}>
                    -
                  </div>
                  <div class="inc qtybutton" onClick={increaseProductAmount}>
                    +
                  </div>
                </div>
                <a
                  className="bk-btn bk-btn-cart ml-3"
                  onClick={(v) => {
                    addToCart({ handle: title, quantity: productCartCount });
                  }}
                >
                  Add To Cart
                </a>
                {excludeNodeEnv() === true && (
                  <a
                    className="bk-btn bk-btn-reconfigure ml-3"
                    onClick={(v) => navigate(reconfigBase)}
                  >
                    Reconfigure
                  </a>
                )}
              </div>
              <Flex
                mb={[5, 3, 3, 4]}
                flexDirection={[`column`, `row`, `row`, `row`]}
              >
                <SocialShare
                  url={alias}
                  title={'Custom Build ' + part_number}
                />
                <Flex ml={[0, 0, 0, 50]} mt={[0, 0, 0, `8px`]} pl={[0, `50px`]}>
                  <ProductMeta />
                </Flex>
              </Flex>
              <div class="pro-desc">
                <div class="spec-desc">{ReactHtmlParser(body)}</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  return null;
}
export default BuildPage;

import React, { useState, useEffect, useContext } from 'react';
import LoaderCenterHorizontal from '../components/Commons/LoaderCenterHorizontal'
import BuildPage from '../templates/build/BuildPage'
import { getBuild } from '../helpers/DrupalHelper';
import StateContext from '../context/StateContext'

export default props => {

  const { data, pageContext, location } = props

  const { key, state, pathname: alias } = location

  const { formlightProduct } = data

  // const { title } = data.formlightFeaturedBuild

  // const { nodes } = data.allFormlightProduct

  const { token } = useContext(StateContext)

  const [product, setProduct] = useState(null)

  const setRemoteProduct = (handle) => {
    getProduct(handle)
  }

  const setLocalProduct = async () => {
    await setProduct(data.formlightBuild)
  }

  useEffect(() => {
    const buildAlias = alias.replace("/build/", "")
    if (
      data.formlightBuild !== null &&
      typeof data.formlightBuild !== "undefined" &&
      data.formlightBuild.title === buildAlias
    ) {
      /* GraphQL query result */
      setLocalProduct();
    } else {
      /* Fetch */
      setRemoteProduct(buildAlias);
    }
  }, [location]);

  const getProduct = async (handle) => {
    await getBuild(
      token,
      handle
    ).then(data => {
      setProduct(data)
    })
    .catch(e => {
      // console.log("error")
      // console.log(e)
    })
  }


  // @TODO: add Helmet

  if(product !== null) {
    return (
      <BuildPage
        product={product}
        parentProduct={formlightProduct} alias={alias}
        />
    )
  }
  return <LoaderCenterHorizontal full />

}

export const query = graphql`
  query formlightFeaturedBuild($title: String, $product: Int) {
    formlightBuild(title: { eq: $title }) {
      title
      part_number
      imageGroup {
        childImageSharp {
          id
        }
      }
      images
      three
      #v
      json
      #body
      product
    }

    formlightProduct(nodeId: { eq: $product }) {
      nodeId
      nodeName
      nodeImages
      imageGroup {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
